@import "./styles/mixins";
@import "./styles/tippy";
@import "./styles/spinner";

// Angular Material Theme
@import "@angular/material/prebuilt-themes/deeppurple-amber.css";

// Mine UI Lib
@import "node_modules/@mineos/mine-ui/styles/global.scss";

:root {
   // PALETTE //
   --mine-white: #fff;
   --mine-black: #000;
   --mine-app-background: #f6f7f9;
   --mine-grey-light: #d1d2d5;
   --mine-grey-light-2: #e9e9ea;
   --mine-grey-light-3: #d5d4d7;
   --mine-grey-normal: #95949a;
   --mine-grey-normal-2: #d5d4d7;
   --mine-grey-normal-3: #939298;
   --mine-grey-dark: #403f49;
   --mine-grey-dark-3: #2b2936;
   --mine-grey-dark-4: #212121;
   --mine-grey-dark-5: #4D525D;
   --mine-grey-3: #6a6971;
   --mine-grey-4: #bfbfc2;
   --mine-grey-5: #4b4b4b;
   --mine-grey-6: #f2f2f2;
   --mine-grey-7: #f4f4f4;
   --mine-grey-8: #f9f9f9;
   --mine-gray-10: #d9d9d9;
   --mine-gray-11: #fbfdfd;
   --mine-gray-12: #E5E5E5;
   --mine-gray-13: #979797;
   --mine-gray-14: #f7f7f7;
   --mine-gray-15: #5C5B64;
   --mine-gray-coal: #515059;
   --mine-gray-16: #f0f2f5;
   --mine-gray-17: #F8F8F8;
   --mine-grey-18: #E1E2E8;
   --mine-grey-19: #FAFBFC;
   --mine-grey-20: #EAEAEA;
   --mine-grey-21:  #F5F5FA;
   --mine-grey-transparent-1: rgba(0, 0, 0, 0.03);

   --mine-blue-grey: #5b5a62;
   --mine-blue-dark: #2b2a35;
   --mine-blue-dark-2: #352BA3;

   --mine-red: #e86161;
   --mine-red-2: #DF2D2D;
   --mine-red-light: #ffa290;
   --mine-red-light-2: #fff4f2;
   --mine-red-light-3: #ffe3de;
   --mine-red-light-4: #FFDCD5;
   --mine-red-light-5: #FCE7E6;
   --mine-red-light-7: #FFEDEA;

   --mine-green: #75edb4;
   --mine-green-2: #E9FEF8;
   --mine-green-3: #186B67;
   --mine-green-4: #41E095;
   --mine-green-light: #a4f0d0;
   --mine-green-light-2: #9ef1ca;
   --mine-green-light-3: #cef4e4;
   --mine-green-light-4: #ecf9f4;
   --mine-green-light-5: #B4EDD2;
   --mine-bright-green: #48BD86;
   --mine-apple-green: #7EBA6F;
   --mine-apple-green-light: #F6F8F1;
   --mine-green-dark: #56BC8C;

   --mine-yellow: #F7C845;
   --mine-dark-yellow: #f9c73d;
   --mine-dark-yellow-2: #EEB20A;
   --mine-dark-yellow-3: #DA9000;
   --mine-light-yellow: #FFF3D2;
   --mine-light-yellow-2: #FFF7DF;
   --mine-light-yellow-3: #FFF8E4;
   --mine-light-yellow-4: #F9F7E6;
   --mine-light-yellow-5: #FFFBF1;

   --mine-gold: #9D9447;

   --mine-purple-dark: #7552f6;
   --mine-purple-light: #9897ee;
   --mine-purple-light-2: #eeeafe;
   --mine-purple-light-3: #bfbff3;
   --mine-purple-light-4: #f6f4fe;
   --mine-purple-light-5: #e5deff;
   --mine-purple-light-6: #F6F4FD;
   --mine-purple-light-7: #E2DDF9;
   --mine-purple-light-8: #FAF9FD;
   --mine-purple-2: #909BFB;

   --mine-pink-dark: #cd4fc0;
   --mine-pink-dark-2: #B547A9;
   --mine-pink-light: #FFE8FD;
   --mine-pink-light-2: #FFF3FE;

   --mine-orange-light: #FCEDE1;

   --mine-blue: #4fa5f4;
   --mine-blue-2: #E5F1FF;
   --mine-blue-3: #3387E3;
   --mine-blue-4: #3689E3;
   --mine-blue-light: #DCEBFC;
   --mine-blue-very-light: #DFEFFF;
   --mine-blue-very-light-2:#D0E0FE;
   --mine-blue-very-light-3:#D2F0FC;
   --mine-dark-blue-2: #032E95;
   --mine-dark-blue-3: #135782;
   --mine-deep-blue: #362CA3;
   --mine-deep-blue-2: #2C0980;
   
   // AVATAR PALETTE //
   --avatar-green: #86b6a1;
   --avatar-yellow: #f6d270;
   --avatar-orange: #efa368;
   --avatar-blue-light: #8dcddb;
   --avatar-blue-dark: #72aff2;

   // DIMENSIONS //
   --full-screen-no-header: calc(100vh - 8rem);
   --full-screen-no-header-mobile: calc(100vh - 4.8rem);

   // FONTS //
   --font-size-9: 10px;
   --font-size-10: 10px;
   --font-size-11: 10px;
   --font-size-12: 12px;
   --font-size-13: 13px;
   --font-size-14: 14px;
   --font-size-16: 16px;
   --font-size-18: 18px;
   --font-size-20: 20px;
   --font-size-21: 21px;
   --font-size-22: 22px;
   --font-size-24: 24px;
   --font-size-28: 28px;
   --font-size-30: 30px;
   --font-size-32: 32px;
   --font-size-38: 38px;
   --font-size-44: 44px;
   --font-size-50: 50px;
   --font-size-66: 66px;

   // BORDER RADIUS //
   --border-radius-3: 3px;
   --border-radius-4: 4px;
   --border-radius-5: 5px;
   --border-radius-6: 6px;
   --border-radius-8: 8px;
   --border-radius-10: 10px;
   --border-radius-12: 12px;
   --border-radius-14: 14px;
   --border-radius-15: 15px;
   --border-radius-16: 16px;
   --border-radius-19: 19px;
   --border-radius-21: 21px;
   --border-radius-24: 24px;
   --border-radius-25: 25px;
   --border-radius-35: 35px;
   --border-radius-30: 30px;
   --border-radius-45: 45px;
   --border-radius-58: 58px;
   --border-radius-100: 100px;
   
   --border-default-grey: 1px solid rgba(43, 42, 53, 0.1);
   --border-default-light-grey: 1px solid var(--mine-grey-6);
   --border-default-light-grey-2: 1px solid var(--mine-grey-light-2);
   --border-default-dark-grey: 1px solid var(--mine-grey-4);
   --border-default-purple: 1px solid var(--mine-purple-dark);

   //BOX SHADOW //
   --box-shadow-grey: 0 1px 12px 0 #e7e7e7;
   --box-shadow-normal: 0 1px 12px 0 var(--mine-grey-6);
   --box-shadow-dark-1: 0 1px 12px rgba(0, 0, 0, 0.1);
   --box-shadow-dark-2: 0 1px 24px 0 rgba(0, 0, 0, 0.1);
   --box-shadow-dark-3: 0 1px 12px rgba(0, 0, 0, 0.05);
   --box-shadow-normal-2: 0 0 20px 2px rgba(0, 0, 0, 0.04);
   --box-shadow-normal-3: 0 2px 12px rgba(0, 0, 0, 0.07);

   // BUTTONS //
   --button-default-height: 4.2rem;

   // LINE HEIGHT //
   --line-height-sm: 1;
   --line-height-lg: 1.5;

   // FORMS BOTTOM PADDING //
   --forms-footer-padding: 4rem;
}

*,
*::after,
*::before {
   margin: 0;
   padding: 0;
   outline: none;
   color: inherit;
   box-sizing: inherit;
   font-weight: inherit;
}

html {
   // This defines 1rem = 10px
   // font-size: 62.5%; //1rem = 10px; 10px/16px = 62.5%

   // Because of bug in chrome, rem-based values have a lower bound of 9px.
   // Use of absolute units is a workaround until issue is fixed (if ever)
   font-size: 10px;

   // @include respond(tablet-port) { // width < 1200?
   //    // font-size: 56.25%; //1rem = 9px; 9/16 = 56.25%
   //    font-size: 9px;
   // }

   // @include respond(mobile) { // width < 600?
   //    // font-size: 50%; //1rem = 8px; 8/16 = 50%
   //    font-size: 8px;
   // }

   background-color: var(--mine-app-background);
}

body {
   box-sizing: border-box;
   font-family: "Lato", sans-serif;
   font-weight: 400;
   line-height: normal;
   color: var(--mine-blue-dark);
   overflow-x: hidden;
}

b,
strong {
   font-weight: 700;
}

button {
   font-family: inherit;
   font-size: 100%;
   border: 0;
}

.chatbot, .form__text-block {
   a {
      color: var(--mine-purple-dark);
      transition: 0.1s;
      cursor: pointer;
   
      &:hover {
         color: var(--mine-purple-light);
         fill: currentColor;
         transition: all 0.1s ease-in;
      }
   }
}

.chatbot {
   ul {
      display: block;
      list-style-type: disc;
      margin-inline-start: 0;
      margin-inline-end: 0;
      padding-inline-start: 4rem;
   }
}

.form__text-block {
   ul {
      display: block;
      list-style-type: disc;
      margin-inline-start: 0;
      margin-inline-end: 0;
      padding-inline-start: 2em;
   }
}

.u-highlight-text {
   background-color: var(--mine-green-light-2);
}

.u-purple-text-color {
   color: var(--mine-purple-dark);
}

.purple-link {
   color: var(--mine-purple-dark);
   text-decoration: none;
}

.ul-indent {
   margin-left: 2rem;
}

::selection {
   background: var(--mine-light-yellow);
}

// Hide scrollbar on overflow
// ::-webkit-scrollbar {
//    display: none; // Chrome, Safari
// }

// UTILITIES //
.u-padding-horizontal-default {
   padding-right: 3rem;
   padding-left: 3rem;

   @include respond(mobile) {
      padding-right: 2.5rem;
      padding-left: 2.5rem;
   }

   @include respond(mobile-mini) {
      padding-right: 2rem;
      padding-left: 2rem;
   }
}

.u-display-block {
   display: block;
}

.full-screen-center {
   display: flex;
   justify-content: center;
   align-items: center;
   height: var(--full-screen-no-header);
   background-color: var(--mine-gray-14);

   @include respond(mobile) {
      height: var(--full-screen-no-header-mobile);
   }

   &--no-header {
      height: 100vh;
   }
}

// box container style for sectioned box elements //
.box-container {
   background-color: var(--mine-white);
   border-radius: var(--border-radius-10);
   box-shadow: var(--box-shadow-normal);
}

// header for the box container //
.box-header {
   font-weight: 700;
   border-bottom: 1px solid var(--mine-grey-normal-2);
   height: 7rem;
   min-height: 7rem;
   font-size: var(--font-size-16);
   display: flex;
   padding-top: 2.5rem;
   padding-left: 2.5rem;
}

.box-header-svg {
   width: 1.6rem;
   height: 1.6rem;
   fill: var(--mine-grey-4);
   margin-left: 1.5rem;
   margin-top: 3px;
}

// BUTTONS //
.mine-button-primary-lg {
   display: block;
   // Button dimesion does not change across different devices, therefore its values are in px
   width: 170px;
   height: var(--button-default-height);
   line-height: var(--line-height-lg);
   border-radius: var(--border-radius-21);
}

// DIALOGS //
ngneat-dialog,
.app-general-dialog-style {
   .ngneat-dialog-backdrop.ngneat-dialog-backdrop-visible {
      background-color: rgb(43, 42, 53, 0.8);
      z-index: 9999;
      .ngneat-dialog-content {
         overflow: unset;
         border-radius: 10px;
         .ngneat-close-dialog {
            display: none;
         }
         .ngneat-close-dialog:hover {
            color: var(--mine-purple-dark);
         }
         .ngneat-close-dialog svg {
            width: 14px;
            height: 14px;
         }
      }
   }
}

.mine-grey-normal {
   color: var(--mine-grey-normal);
}

// beamer notifications css
.beamerTrigger .beamer_icon.active {
   width: 2.1rem;
   height: 2.1rem;
   margin-top: 10px;
   margin-right: 5px;
   font-size: var(--font-size-12);
   line-height: 21px;
}


//material datepicker override
.mat-calendar-body-selected { 
   background-color: var(--mine-purple-dark);
}
.mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover>.mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
   background-color: var(--mine-purple-light-4);
}
.mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
   border-color: var(--mine-purple-light);
   background-color: transparent !important;
}
.mat-calendar {
   font-family: var(--font-lato);
}
.mat-focus-indicator.mat-calendar-period-button.mat-button.mat-button-base {
   font-weight: bold;
   font-family: var(--font-lato);
   font-size: var(--font-size-13);
}

.meetings-iframe-container {
   position: absolute;
   width: 100%;
   height: 100%;
   bottom: 9rem;
   z-index: -1;

   iframe {
      position: absolute;
      top: 0;
      left: 0;
   }
}

color-picker {
   & .color-picker {
      border: transparent;
      border-radius: var(--border-radius-10);
      box-shadow: var(--box-shadow-dark-2);

      & > .saturation-lightness {
         border-top-left-radius: var(--border-radius-10);
         border-top-right-radius: var(--border-radius-10);
      }
   }
}

.blink {
   -webkit-animation: blink-animation 1.4s infinite alternate;
   -moz-animation: blink-animation 1.4s infinite alternate;
   -o-animation: blink-animation 1.4s infinite alternate;
   animation: blink-animation 1.4s infinite alternate;
}

@-webkit-keyframes blink-animation {
  0%   { opacity: 1; }
  100% { opacity: 0.4; }
}
@-moz-keyframes blink-animation {
  0%   { opacity: 1; }
  100% { opacity: 0.4; }
}
@-o-keyframes blink-animation {
  0%   { opacity: 1; }
  100% { opacity: 0.4; }
}
@keyframes blink-animation {
  0%   { opacity: 1; }
  100% { opacity: 0.4; }
}

.cdk-overlay-container { z-index: 10000; }

.cdk-drag-handle {
   cursor: grab;
}

.cdk-drag-dragging {
   .cdk-drag-handle {
      cursor: grabbing;
   }
}
